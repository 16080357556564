//fixing fontawesome icons size
const styles = require("@fortawesome/fontawesome-svg-core/styles.css")
const config = require("@fortawesome/fontawesome-svg-core")

config.autoAddCss = false

require("typeface-roboto")
require("typeface-satisfy")
require("@openfonts/roboto_cyrillic")
const cc = require("cookieconsent")

window &&
  window.cookieconsent.initialise(
    {
      palette: {
        popup: {
          background: "#25374b",
        },
        button: {
          background: "#1b5a90",
        },
      },
      theme: "classic",
      position: "bottom-right",
      content: {
        message:
          "We use anonymous analytics to improve website performance. We do not track using cookies.",
      },
    },
    () => {
      console.log("cookie success")
    },
    (e) => {
      console.error("cookie init error", e)
    }
  )
